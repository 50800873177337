//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    BackTop: () =>
      import(/* webpackChunkName: "BackTop" */ 'vue-multiple-back-top'),
    NewAppFooter: () =>
      import(
        /* webpackChunkName: "NewAppFooter" */ '@/components/footer/NewAppFooter'
      ),
    MiModernAlert: () =>
      import(
        /* webpackChunkName: "MiModernAlert" */ '@/components/alert/MiModernAlert'
      ),
    Header: () => import(/* webpackChunkName: "Header" */ '~/components/Header')
    // NewAppFooter,
    // MiModernAlert,
    // Header
  },
  layout: '',
  asyncData({ req }) {
    return {
      name: req ? 'server' : 'client'
    }
  },
  data() {
    return {
      alertData: {
        alertType: 'News',
        alertMessage:
          'Thank you for your support! We are currently carry out and deliver only. We are in the process of reopening.'
      }
    }
  }
}
